import React from 'react'
import { CVData } from './data';


import CV from 'react-cv'
const Homepage = () => {
  return (
    <CV   
        {...CVData} 
        branding ={false}
        />
  )
}

export default Homepage

export const CVData = {
    personalData: {
      name: 'Arpit Mohanty',
      title: 'Software Engineer',
      image: '/profile.jpg',
      contacts: [
        { type: 'email', value: 'arpitjga@gmail.com' },
        { type: 'phone', value: '+61 412076440' },
        { type: 'location', value: 'Perth,WA' },
        { type: 'linkedin', value: 'linkedin.com/in/arpit-mohanty-7338a711b' },
        { type: 'github', value: 'github.com/mohantyArpit' }
      ]
    },
    sections: [
      {
        type: 'text',
        title: 'Career Profile',
        content: 'With almost 6 years of working experience across several technologies as a Software Engineer in React Js, Node Js, Typescript, AWS and DEVOPS. Experience with TDD and BDD in an Agile Environment for software development.A disciple of 12 Factor Apps',
        icon: 'usertie'
      },
      {
        type: 'experiences-list',
        title: 'Experiences',
        description: 'Optional',
        icon: 'archive',
        items: [
          {
            title: 'Team Lead',
            company: 'TATA Consultancy Services',
            description: 'Working as a Team Lead across all pillars of a project , providing technical guidance,',
            datesBetween: '2022.08 - Present',
            descriptionTags: ['AWS','Serverless Framework','Typescript','Node.js','Angular JS']
          },
          {
            title: 'Full Stack Developer',
            company: 'TATA Consultancy Services',
            description: 'Working as a full stack developer in a Serverless React Project',
            datesBetween: '2020.06 - 2022.07',
            descriptionTags: ['AWS','Serverless Framework','Typescript','Node.js','React JS',"Cypress","BDD Testing"]
          },
          {
            title: 'Software Developer',
            company: 'TATA Consultancy Services',
            description: 'Working as Cloud Developer to provide custom AWS Service Catalog Products for Repeatable Patterns across the Organization',
            datesBetween: '2019.04 - 2020.06',
            descriptionTags: ['AWS CDK', 'Service Catalog','Cloud Formation',"Step Function","Metrics and Alarms","Example Mapping"]
          },
          {
            title: 'Backend Node Developer',
            company: 'TATA Consultancy Services',
            description: 'Working as Node JS Developer writing API on API Gateway, backed by NODE Js Lambdas and Cloud Formation Scripts for deployment',
            datesBetween: '2018.03 - 2019.03',
            descriptionTags: ['AWS Lambda', 'API Gateway','Cloud Formation',"Node JS","Mocha and Chai","Dynamo DB"]
          },
          {
            title: 'Developer',
            company: 'TATA Consultancy Services',
            description: 'Working as DOT NET Developer writing API and Enhancing Features of a product hosted on Sharepoint',
            datesBetween: '2017.02 - 2018.03',
            descriptionTags: ['DOT NET', 'C #','KnockOut JS',"Kendo UI"]
          }
        ]
      },
      
      {
        type: 'common-list',
        title: 'Achievements and Certificates',
        description: '',
        icon: 'comments',
        items: [
          {
            title: 'AWS Certified Solutions Architect-Associate',
            authority: 'AWS',
            authorityWebSite: 'https://www.credly.com/badges/e5d101b0-6606-4be9-a302-615d339676ec/linked_in_profile'
          },
          {
            title: 'Winner of Woodside AWS Sustainability Hackhathon',
            authority: 'Woodside',
          
          }
        ]
      },
      {
        type: 'common-list',
        title: 'Languages',
        icon: 'language',
        items: [
          {
            authority: 'English',
            authorityMeta: 'Professional'
          },
          {
            authority: 'Hindi',
            authorityMeta: 'Professional'
          },
          {
            authority: 'Bengali',
            authorityMeta: 'Professional'
          },
          {
            authority: 'Odia',
            authorityMeta: 'Intermediate'
          }          
        ]
      },
      {
        type: 'tag-list',
        title: 'Skills Proficiency',
        icon: 'rocket',
        items: ['AWS','React JS', 'NODE JS', 'Typescript', 'BDD', 'NO Sql','GraphDatabase-Neo4J' ]
      },
      {
        type: 'tag-list',
        title: 'Hobbies & Interests',
        icon: 'cubes',
        items: ['Cooking', 'Running']
      }
    ]
  }
  